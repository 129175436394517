<template>
  <div data-app>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" sm="6" md="6" style="color: #e33354" class="ml-4">
            <h3>{{ cardTitle }}</h3>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="3" md="3">
            <v-text-field
              v-model="search"
              append-icon="search"
              :label="$t('FORMS.search')"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="holidayCollection"
          :search="search"
          :sort-by="['id']"
          sort-desc="true"
          :loading="loadingTable"
          @click:row="handleClick"
          :footer-props="{
            'items-per-page-options': [100],
          }"
        >
          <template #header="{ props: { headers } }">
            <thead class="v-data-table-header">
              <tr>
                <th>
                  <v-select
                    v-model="filterData.year"
                    :items="[
                      { id: null, year: 'Minden év' },
                      ...yearCollection,
                    ]"
                    item-text="year"
                    item-value="id"
                    @change="fetchHoliday"
                  ></v-select>
                </th>
                <th>
                  <v-select
                    v-if="permissionCan('viewAny')"
                    v-model="filterData.employee_id"
                    :items="[
                      { id: null, fullName: 'Összes munkatárs' },
                      ...employeeCollection,
                    ]"
                    item-text="fullName"
                    item-value="id"
                    @change="fetchHoliday"
                  ></v-select>
                </th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>

                <th>
                  <v-btn
                    v-if="canSave"
                    color="primary"
                    elevation="2"
                    @click="handleSaveData"
                  >
                    {{ $t("FORMS.save") }}
                  </v-btn>
                </th>
              </tr>
            </thead>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="handleCloseDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </v-toolbar>
          </template>
          <template v-slot:item.available_days="{ item }">
            <span
              v-if="inlineEdit.id != item.id"
              :class="{ edited: item.edited }"
            >
              {{ item.available_days }}
            </span>
            <v-text-field
              v-else
              label="Napok"
              v-model="inlineEdit.available_days"
              class="inline-input"
              type="number"
              :min="0"
              v-on:input="handleChangeInlineInput"
              @blur="handleValidateFields($event, { ...inlineEdit })"
            ></v-text-field>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions class="mb-2">
        <v-row>
          <v-spacer></v-spacer>
          <v-btn
            v-if="canSave"
            color="primary"
            elevation="2"
            @click="handleSaveData"
            class="mb-4 mr-4"
          >
            {{ $t("FORMS.save") }}
          </v-btn>
        </v-row>
      </v-card-actions>
      <SnackBarInfoComponent :snackbarInfo="snackbarInfo">
      </SnackBarInfoComponent>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import ApiService from "@/core/services/api.service";

import DeleteModalDialog from "@/view/components/DeleteModalDialog";
export const PERMISSION_TO = "humanResources.holiday.";
import { INITIAL_SNACKBAR_INFO } from "@/view/components/SnackBarInfoComponent.vue";
import SnackBarInfoComponent from "@/view/components/SnackBarInfoComponent";

export const initialInlineEdit = {
  id: null,
  available_days: 0,

  num_of_approved_holiday: 0,
  num_of_sent_holiday: 0,
};

export default {
  name: "Holiday",
  components: { DeleteModalDialog, SnackBarInfoComponent },

  data() {
    return {
      cardTitle: "Szabadságok",
      search: "",
      routePath: "/humanResource/holiday/",
      permissionTo: PERMISSION_TO,
      permissions: JSON.parse(localStorage.permissions),
      headers: [
        {
          text: "Munkaév",
          value: "year",
        },
        {
          text: "Munkatárs neve",
          value: "fullName",
        },

        {
          text: "Éves szabadságkeret",
          value: "available_days",
        },
        {
          text: "Engedélyezésre vár",
          value: "num_of_sent_holiday",
        },
        {
          text: "Engedélyezett",
          value: "num_of_approved_holiday",
        },
        {
          text: "Elutasított",
          value: "num_of_rejected_holiday",
        },
        {
          text: "Felhasználható keret",
          value: "num_of_remaining_holiday",
        },
      ],

      dialogDelete: false,

      filterData: {
        employee_id: null,
        year: null,
      },

      holidayCollection: [],
      loadingTable: false,
      inlineEdit: Object.assign({}, initialInlineEdit),
      holidays: [],
      snackbarInfo: INITIAL_SNACKBAR_INFO,
    };
  },

  computed: {
    ...mapGetters(["statuses", "yearCollection", "employeeCollection"]),

    canSave() {
      let validateDays = this.holidayCollection.find((item) => {
        return (
          +item.num_of_sent_holiday + item.num_of_approved_holiday >
          +item.available_days
        );
      });

      return (
        !!this.permissionCan("update") &&
        !!this.filterData.year &&
        this.filterData.year.status != 2 &&
        this.holidays.length > 0 &&
        !validateDays
      );
    },
  },

  watch: {
    dialogDelete(val) {
      val || this.handleCloseDelete();
    },
  },

  methods: {
    ...mapActions(["fetchYear", "fetchEmployee"]),

    fetchHoliday() {
      this.loadingTable = true;
      this.holidays = [];
      ApiService.post("humanResources/holiday", this.filterData)
        .then(({ data }) => {
          this.holidayCollection = [
            ...data.map((item) => {
              item.fullName =
                item.user.surname + " " + item.user.christian_name1;
              if (item.user.christian_name2)
                item.fullName += " " + item.user.christian_name2;
              return item;
            }),
          ];
          // console.log("this.holidayCollection", this.holidayCollection);
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },

    fetchModel() {
      // filterData.year

      // v-if="permissionCan('viewAny')"

      this.fetchEmployee();
      return this.fetchYear().then(() => {
        if (this.permissionCan("viewAny")) {
          let currentYear = new Date().getFullYear();
          if (this.yearCollection.find((y) => y.year == currentYear)) {
            this.filterData.year = currentYear;
          } else {
            let maxYear = Math.max(...this.yearCollection.map((y) => y.year));

            if (maxYear) {
              this.filterData.year = maxYear;
            }
          }
          this.fetchHoliday();
        } else {
          this.fetchHoliday();
        }
      });
    },

    permissionCan(action) {
      return this.permissions.find((x) => x.name == this.permissionTo + action);
    },

    handleClick(row) {
      if (
        (this.filterData.year && this.filterData.year.status == 2) ||
        !this.permissionCan("update")
      )
        return null;

      this.inlineEdit.id = row.id;
      this.inlineEdit.available_days = row.available_days;

      this.inlineEdit.num_of_approved_holiday = row.num_of_approved_holiday;
      this.inlineEdit.num_of_sent_holiday = row.num_of_sent_holiday;
    },

    handleSaveData() {
      this.loadingTable = true;

      ApiService.post("humanResources/holiday/save", this.holidays)
        .then(() => {
          // .then(({ data }) => {
          // this.holidayCollection = [
          //   ...data.map((item) => {
          //     item.fullName =
          //       item.user.surname + " " + item.user.christian_name1;
          //     if (item.user.christian_name2)
          //       item.fullName += " " + item.user.christian_name2;
          //     return item;
          //   }),
          // ];
          this.fetchHoliday();
        })
        .catch((error) => {
          console.log("Error!: ", error);
        })
        .finally(() => {
          this.inlineEdit = Object.assign({}, initialInlineEdit);
          this.holidays = [];
          this.loadingTable = false;
        });
    },

    handleChangeInlineInput(input) {
      // if (!input || input == "") {
      //   return null;
      // }
      // this.inlineEdit.available_days = parseInt(input);
      this.inlineEdit.available_days = input;
      this.holidays = this.holidays.filter((i) => i.id != this.inlineEdit.id);

      this.holidayCollection.find(
        (i) => i.id == this.inlineEdit.id
        // ).available_days = parseInt(input);
      ).available_days = input;

      this.holidayCollection.find(
        (i) => i.id == this.inlineEdit.id
      ).edited = true;
      // debugger;
      this.holidays.push({ ...this.inlineEdit });
    },

    handleValidateFields(event, editedData) {
      if (
        editedData.num_of_sent_holiday + editedData.num_of_approved_holiday >
        editedData.available_days
      ) {
        this.snackbarInfo.text =
          "Az éves keret nem lehet kisebb, mint az összes már megigényelt szabadság!";
        this.snackbarInfo.icon = "mdi-alert-circle";
        this.snackbarInfo.color = "red";
        this.showSnackBarInfo();
      }
    },

    showSnackBarInfo() {
      let snackbarInfo = this.snackbarInfo;

      snackbarInfo.active = true;

      this.snackbarInfo = snackbarInfo;
    },
  },

  mounted() {
    this.loadingTable = true;

    this.fetchModel().then(() => (this.loadingTable = false));
  },
};
</script>

<style scoped>
.inline-input ::v-deep input {
  font-size: 0.7em;

  text-transform: capitalize;
}

.inline-input ::v-deep label {
  font-size: 0.7em;
}

.inline-input ::v-deep button {
  font-size: 0.7em;
}

.edited {
  color: #e33354;
}
</style>
