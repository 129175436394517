var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-app":""}},[_c('v-card',[_c('v-card-title',[_c('v-row',[_c('v-col',{staticClass:"ml-4",staticStyle:{"color":"#e33354"},attrs:{"cols":"12","sm":"6","md":"6"}},[_c('h3',[_vm._v(_vm._s(_vm.cardTitle))])]),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","sm":"3","md":"3"}},[_c('v-text-field',{attrs:{"append-icon":"search","label":_vm.$t('FORMS.search'),"single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.holidayCollection,"search":_vm.search,"sort-by":['id'],"sort-desc":"true","loading":_vm.loadingTable,"footer-props":{
          'items-per-page-options': [100],
        }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"header",fn:function(ref){
        var headers = ref.props.headers;
return [_c('thead',{staticClass:"v-data-table-header"},[_c('tr',[_c('th',[_c('v-select',{attrs:{"items":[
                    { id: null, year: 'Minden év' } ].concat( _vm.yearCollection ),"item-text":"year","item-value":"id"},on:{"change":_vm.fetchHoliday},model:{value:(_vm.filterData.year),callback:function ($$v) {_vm.$set(_vm.filterData, "year", $$v)},expression:"filterData.year"}})],1),_c('th',[(_vm.permissionCan('viewAny'))?_c('v-select',{attrs:{"items":[
                    { id: null, fullName: 'Összes munkatárs' } ].concat( _vm.employeeCollection ),"item-text":"fullName","item-value":"id"},on:{"change":_vm.fetchHoliday},model:{value:(_vm.filterData.employee_id),callback:function ($$v) {_vm.$set(_vm.filterData, "employee_id", $$v)},expression:"filterData.employee_id"}}):_vm._e()],1),_c('th'),_c('th'),_c('th'),_c('th'),_c('th',[(_vm.canSave)?_c('v-btn',{attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveData}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)])])]}},{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('DeleteModalDialog',{attrs:{"dialogDelete":_vm.dialogDelete},on:{"closeDelete":_vm.handleCloseDelete,"deleteItemConfirm":_vm.handleDeleteItemConfirm}})],1)]},proxy:true},{key:"item.available_days",fn:function(ref){
                  var item = ref.item;
return [(_vm.inlineEdit.id != item.id)?_c('span',{class:{ edited: item.edited }},[_vm._v(" "+_vm._s(item.available_days)+" ")]):_c('v-text-field',{staticClass:"inline-input",attrs:{"label":"Napok","type":"number","min":0},on:{"input":_vm.handleChangeInlineInput,"blur":function($event){return _vm.handleValidateFields($event, Object.assign({}, _vm.inlineEdit))}},model:{value:(_vm.inlineEdit.available_days),callback:function ($$v) {_vm.$set(_vm.inlineEdit, "available_days", $$v)},expression:"inlineEdit.available_days"}})]}}])})],1),_c('v-card-actions',{staticClass:"mb-2"},[_c('v-row',[_c('v-spacer'),(_vm.canSave)?_c('v-btn',{staticClass:"mb-4 mr-4",attrs:{"color":"primary","elevation":"2"},on:{"click":_vm.handleSaveData}},[_vm._v(" "+_vm._s(_vm.$t("FORMS.save"))+" ")]):_vm._e()],1)],1),_c('SnackBarInfoComponent',{attrs:{"snackbarInfo":_vm.snackbarInfo}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }